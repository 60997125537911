:root {
  /* Main Theme Color */
  --main: #0c0f15;

  /* Accent Theme Color */
  --accent: #212529;

  /* Theme Overlay Color */
  --overlay: rgba(14, 17, 25, 0.65);

  /* Background Image */
  --bg-img: url("assets/images/background.jpg");
}

#root {
  font-family: "alata", serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #ababab;
  /* Background */
  background: linear-gradient(0deg, var(--overlay), var(--overlay)),
    var(--bg-img);
  background-color: var(--main);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

body {
  /* margin: 0; */
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Initial styling
-------------------------------------------------- */

.vertical-center {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
  justify-content: center;
}

.text-main {
  color: #0f0 !important;
}

/* Link Stylings */

a {
  color: inherit;
  text-decoration: none;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

a:hover,
a:focus {
  color: #0f0;
  text-decoration: none;
  outline: 0 none;
}

/* Selection color */

::-moz-selection {
  background-color: #0f0;
}

::selection {
  background-color: #0f0;
}