/* Contact Form
-------------------------------------------------- */

.border {
  width: 100px;
  height: 5px;
  background: var(--main);
  margin: 40px auto;
}

.contactForm {
  min-width: 650px;
}

.contactFormLabel {
  margin: 0 0 10px 0 !important;
  color: var(--text-main);
  font-family: "alata", sans-serif;
}

.contactFormText {
  font-family: "alata", sans-serif;
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 2px solid var(--main);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.5);
  color: var(--text-main);
  padding: 15px 25px;
  outline: none;
  transition: 0.5s;
  /* Box Shadow */
  -webkit-box-shadow: 0px 0px 5px 2px var(--glow);
  -moz-box-shadow: 0px 0px 5px 2px var(--glow);
  box-shadow: 0px 0px 5px 2px var(--glow);
}

.contactFormText:focus{
  box-shadow: 0 0 10px 4px rgb(35, 200, 35);
}